export const colors = {
  primary: '#6741D9',
  white: '#ffffff',
  grayBackground: '#f1f3f5',
  grayBold: '#212529',
  grayRegular: '#495057',
  grayBorder: '#ced4da',
  grayLight: '#868e96',
  black: '#000000',
  disabled: '#ADB5BD',
  border: '#DEE2E6',
  border2: '#e9ecef',
  error: '#fa5252',
  naver: '#02c73c',
  kakao: '#f9e100',
  facebook: '#3c5997',
};
