import facepaint from 'facepaint';
import type { DynamicStyleFunction } from 'facepaint';
import React, { createContext, ReactNode } from 'react';

export const defaultMQ: DynamicStyleFunction = facepaint([]);

interface ResponsiveContextValue {
  mq: DynamicStyleFunction;
}

export const ResponsiveContext = createContext<ResponsiveContextValue>({
  mq: defaultMQ,
});

ResponsiveContext.displayName = 'ResponsiveContext';

interface Props {
  mediaQueries: string[];
  children?: ReactNode;
}

export function ResponsiveProvider({ mediaQueries, children }: Props) {
  return (
    <ResponsiveContext.Provider value={{ mq: facepaint(mediaQueries) }}>
      {children}
    </ResponsiveContext.Provider>
  );
}
