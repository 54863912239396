import type { As } from '@florencecard-components/as';
import { Box, BoxProps } from '@florencecard-components/box';
import React, { forwardRef, Ref } from 'react';

export type TxtProps<Element extends As = 'span'> = BoxProps<Element>;

function _Txt<Element extends As = 'span'>(
  {
    as = 'span',
    fontSize = 'inherit',
    fontWeight = 'inherit',
    lineHeight = 'inherit',
    color = 'inherit',
    padding = 0,
    margin = 0,
    children,
    ...props
  }: TxtProps<Element>,
  ref: Ref<HTMLElement>,
) {
  return (
    <Box
      ref={ref}
      as={as}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
      padding={padding}
      margin={margin}
      {...props}
    >
      {children}
    </Box>
  );
}

export const Txt = forwardRef(_Txt);
